
import React from "react";
import "./Banner.css"


function Banner(){
   return (
    <header className="banner">

      <img src="./imagens/Logo.svg" alt="Banner img"/>
      <div>
      <h1>Nossos Momentos <br/> Juntos </h1>
      </div>
    </header>
    
    )
}

export default Banner;